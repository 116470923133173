/* .arrow{
	position: absolute;
	top: 320px;
	left:50%;
    z-index: 1;
	transform: translateX(-50%);
	color:#ffffff;
}

.arrow::before{
	content:"\1F893";
    display: none !important;
	font-size:100px;
}
.spinner-wrapper{
    display: flex;
    margin: 10% 0%;
    flex-direction: column;
    align-items: center;
}
.container{
	width: 17rem;
    height: 17rem;
    background-color: #ccc;
    border-radius: 50%;
    border: 15px solid gold;
    position: relative;
    overflow: hidden;
    transition: 5s;
}

.container div{
    height: 50%;
    width: 102px;
    position: absolute;
    clip-path: polygon(100% 0% , 50% 100% , 0% 0% );
    transform: translateX(-50%);
    transform-origin: bottom;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;
    left: 70px;
}

.container .one{
	background-color: #3f51b5;
	left:50%;
}
.container .two{
	background-color: #ff9800;
	transform: rotate(45deg);
}
.container .three{
	background-color: #e91e63;
	transform:rotate(90deg);
}
.container .four{
	background-color: #4caf50;
	transform: rotate(135deg);
}
.container .five{
	background-color: #009688;
	transform: rotate(180deg);
}
.container .six{
	background-color: #795548;
	transform: rotate(225deg);
}
.container .seven{
	background-color: #9c27b0;
	transform: rotate(270deg);
}
.container .eight{
	background-color: #f44336;
	transform: rotate(315deg);
}



#spin{
    margin: 1rem 0;
    text-align: center;
    background-color: #870000;
    text-transform: uppercase;
    border: 1px solid #323232;
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    width: 100%;
    height: 3rem;
    font-family: sans-serif;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    letter-spacing: 1px;
}


@media (max-width: 500px) 
{
    .arrow{
	position: absolute;
	top: 320px;
	left:50%;
    z-index: 1;
	transform: translateX(-50%);
	color:#ffffff;
}

}
@media (max-width: 380px) 
{
    .arrow{
	position: absolute;
	top:320px;
	left:50%;
    z-index: 1;
	transform: translateX(-50%);
	color:#ffffff;
}


}

@media (max-width: 340px) 
{
    .arrow{
	position: absolute;
	top:340px;
	left:50%;
    z-index: 1;
	transform: translateX(-50%);
	color:#ffffff;
}

   
}




 */




/* ====Wheel Of Fortune =====*/

.wheel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 23rem;
    padding: 0;
  }
  
  .border {
    position: absolute;
    width: 20rem;
    height: 20rem;
    top: 425px;
    background: #00000000;
    border-radius: 100%;
    background-image: linear-gradient(
      to bottom left,
      #000 42%,
      #fbf5ea 50%,
      #000 58%
    );
    box-shadow: inset 1px 1px 1px 1px #fff, inset -1px -1px 1px 1px #000;
  }
  
  .pointer {
    position: absolute;
    width: 30px;
    height: 60px;
    background: url(../img/arrow.png) no-repeat;
    background-size: 30px;
    top: 420px;
    z-index: 5;
    transform-origin: center 38.6%;
  }
  .base {
    position: absolute;
    width: 50px;
    height: 25px;
    background: url(../img/arrow-base.png) no-repeat;
    background-size: 50px;
    top: 424px;
    z-index: 2;
    transform-origin: center 38.6%;
  }
  .wheel-content{
    display: block;
    position: absolute;
    border-radius: 100%;
    width: 92.8%;
    height: 92.8%;
    top: 3.6%;
    left: 3.6%;
    background: none;
    overflow: hidden;
  }
  
  
  .wheel-content::after{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    box-shadow: inset 1px 1px 2px 1px #000, inset -1px -1px 2px 1px #fff, inset 0 0 8px 4px #000;
    pointer-events: none;
  
  }
  
  #spin {
    
    position: absolute;
    background: gold;
    margin: 1rem 0;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid #32323200;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    width:3.5rem;
    height: 3.5rem;
    font-family: sans-serif;
    border-radius: 54px;
    cursor: pointer;
    outline: none;
    top:6.5rem;
    left: 7.5rem;
    letter-spacing: 1px;
    bottom: 12.5rem;
    box-shadow: inset 1px 1px 8px -1px #434343, inset -1px -1px 8px -1px gold, 0px 0px 15px 1px #434343;
    transform-origin: center 38.6%;
  } 


  .container{
	width: 18.5rem;
    height: 18.5rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: 5s;
}

.container div{
    height: 50%;
    width: 43%;
    position: absolute;
    clip-path: polygon(100% 0% , 50% 100% , 0% 0% );
    transform: translateX(-50%);
    transform-origin: bottom;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;
    left: 85px;

}

.container .one{
	background-color: #3f51b5;
	left:50%;
}
.container .two{
	background-color: #ff9800;
	transform: rotate(45deg);
}
.container .three{
	background-color: #e91e63;
	transform:rotate(90deg);
}
.container .four{
	background-color: #4caf50;
	transform: rotate(135deg);
}
.container .five{
	background-color: #009688;
	transform: rotate(180deg);
}
.container .six{
	background-color: #795548;
	transform: rotate(225deg);
}
.container .seven{
	background-color: #9c27b0;
	transform: rotate(270deg);
}
.container .eight{
	background-color: #f44336;
	transform: rotate(315deg);
}
/* 
.decoration{
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-image: radial-gradient(circle at 0 0,#fff,#fff 30%,transparent 60%),radial-gradient(circle at 0 0,#ffff34,#ffff34 50%,#000 100%);
} */



/* To center the spinner*/
.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #870000;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
