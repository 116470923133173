* {
  box-sizing: border-box;
}

/* new bg color :==#870000 */
#root {
  max-width: 30rem !important;
  width: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-flex;

  -webkit-justify-content: center;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #bac6cf;
}
span {
  font-weight: 500;
  color: #870000;
}
span svg {
  font-weight: 500;
  color: #ffffff;
}
a {
  text-decoration: none;
}
.btn:disabled {
  background: #bac6cf;
}

.btn {
  cursor: pointer;
  width: 100%;
  color: #fff;
  height: 2.5rem;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  align-self: center;
  background: #870000;
  text-align: center;
}
select {
  text-decoration: none !important;
}
label {
  font-size: small;
}
h4,
h5,
h6 {
  margin: 3px !important;
}
p {
  margin: 1px !important;
  font-size: smaller;
  font-weight: 500;
}

#app {
  max-width: 30rem;
  width: 100%;
  overflow: hidden;
}
/***** All type of containers *****/
.app_container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: #fff;
  margin: 0% !important;
  padding: 0% !important;
}

.content_container {
  max-width: 30rem !important;
  margin: 3rem 0 5rem 0;
  padding: 1rem 0.5rem;
}

.tabbar_container {
  z-index: 1;
}
/* .tabbar_container ul{
    padding: 3rem!important;
    height: 2rem;
    margin: 0%!important;
    display:flex;
    flex-direction: row ;
    justify-content: space-around;
    align-items: baseline;
    background-color: #88e9f4;
} */

.navbar {
  display: flex;
}

.header {
  max-width: 30rem;
  position: fixed;
  width: 100%;
  background: #870000;
  color: white;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 0 0.5rem;
  z-index: 100;
  height: 3.5rem;
  box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 30%);
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 1rem 0;
  padding: 0 0;
  row-gap: 5px;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  box-shadow: 0 1px 10px rgb(152 182 184 / 50%);
}
.wnr_crd {
  width: 7rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 1rem 0.5rem;
  row-gap: 2px;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  box-shadow: 0 1px 10px rgb(152 182 184 / 50%);
}
.content.A {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 0 1rem;
  row-gap: 15px;
  border-radius: 25px;
  box-shadow: 0 0px 15px rgb(152 182 184 / 50%);
}
.meWon {
  background: green;
  font-size: 11px;
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 2px;
}
.meWona {
  background: rgb(223, 10, 10);
  font-size: 11px;
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 2px;
}

.content .c1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 1rem;
  justify-content: space-between;
}
.c1 img {
  align-self: end;
  border-radius: 99px;
  width: 40%;
  border: 2px solid #ffffff;
  margin: 0.5rem 0;
}
.content .c2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.c2 img {
  align-self: end;
  border-radius: 99px;
  width: 20%;
  border: 2px solid #ffffff;
  margin: 0.5rem 0;
}
.L1 {
  display: flex;
  flex-direction: column;
  width: 60%;
  color: #870000;
}
.L2 {
  padding: 0 0.5rem;
}

.content.z {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #ffa631, #e6ff47);
}

.content .c3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.c3 img {
  border-radius: 50%;
  width: 50%;
  border: solid #ffffff;
}
.c3 Link {
  text-align: center !important;
}

.card-Scrl {
  max-width: 98%;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
  transition-property: transform;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
.card-slide {
  flex: 0 0 auto;
  position: relative;
  transition-property: transform;

  cursor: pointer;
}
.referearn {
  font-size: 11px;
  background: green;
  color: white;
  padding: 2px;
  border-radius: 5px;
}

.money-lottery {
  padding: 0 0.5rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(1, 2fr);
  grid-template-columns: repeat(2, 2fr);
}

.money-lottery img {
  align-self: end;
  border-radius: 20%;
  width: 40%;
  border: 2px solid #ffffff;
}
.money-lotery .L2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  color: #ffffff;
}

.asset-lottery {
}

.mini-game {
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(1, 2fr);
  /* grid-template-columns: repeat(2, 2fr); */
}
.mini-game {
  margin: 0 0 1rem 0;
  font-size: 16px;
  font-weight: 600;
}

.num-screen {
  padding: 1rem 1rem;
  background-color: #ffffff;
  border-radius: 15px;
  font-size: 30px;
  display: grid;
  justify-content: space-between;
  overflow: hidden;
  grid-gap: 1rem;
  grid-template-rows: repeat(1, 2fr);
  grid-template-columns: repeat(4, 0fr);
}
.num1 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #3f51b5;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num2 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #ff9800;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num3 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #e91e63;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num4 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #4caf50;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num5 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #009688;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num6 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #795548;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num7 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #9c27b0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.num8 {
  color: #ffffff;
  cursor: pointer;
  width: 4rem;
  text-align: center;
  background: #f44336;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.5);
}
.banner {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
  padding: 1rem;
  row-gap: 15px;
}
.join-btn {
  max-width: 30rem;
  padding: 1.5rem 0.5rem;
  z-index: 10;
  display: grid;
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  width: 100%;
  background: #ffffff;
  border: solid #ffffff;
  grid-template-columns: repeat(2, 2fr);
  align-items: center;
  justify-items: center;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
}

.users-list {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 10px;
  row-gap: 10px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.w-1 .user-img {
  max-width: 60px !important;
  border-radius: 40px !important;
  border: solid gold;
}
.w-2 .user-img {
  max-width: 60px !important;
  border-radius: 40px !important;
  border: solid silver;
}

.w-3 .user-img {
  max-width: 60px !important;
  border-radius: 40px !important;
  border: solid burlywood;
}

.user-img {
  max-width: 50px;
  width: 100%;
  border-radius: 40px !important;
  height: auto;
  border: solid #d6d5d5;
}

.user-name {
  font-size: 12px;
}
.winner-name {
  padding-top: 15px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.lottery-prize {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}
.slider {
  max-width: 30rem;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}
.slide {
  flex: 0 0 100%;
  transition: transform 1s ease;
}
.slider img {
  max-width: 30rem;
  width: 100%;
  object-fit: cover;
}

.games {
  display: flex;
  margin: 1.5rem 0 0 0;
}
.game-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0;
}

.game-icon a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.game-icon img {
  max-width: 14rem !important;
  width: 30%;
  border: solid #6c7486;
  border-radius: 50px !important;
  height: auto;
  margin: 0 5px 0 0;
}
.game-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart {
  margin: 1rem 0;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.chart tbody {
  text-align: center;
}

.winners {
  background: #ffffff url(../img/winner-bg.svg) no-repeat;
  background-position-x: center;
  background-position-y: -2rem;
  background-size: 27rem;
}

.b-s {
  padding: 1rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/*====login&signup page ====*/

.login_container {
  height: 100vh;
  padding-inline: 0.53333rem;
  white-space: nowrap;
}

.backnav {
  z-index: 100;
  display: flex;
  padding: 1rem 1rem;
  position: fixed;
  top: 0rem;
}
.nav {
  width: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.login__container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10% 0;
}

.login_container-form {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-bottom: 0rem;
  padding: 1rem 2rem;
  row-gap: 1px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
}
.signup_container-form {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-bottom: 0rem;
  padding: 2rem;
  row-gap: 5px;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
}
.step1 {
  display: contents;
}
.step2 {
  display: contents;
}
.login__container-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #870000c2;
  border-radius: 25px 25px 0 0;
  color: #ffffff;
  margin: 4rem 0 0 0;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.5);
}
.subheading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.login__id {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*====OTP=====*/

.input-field {
  display: flex;
  flex-direction: column;
}
.input-field .num-drpdwn {
  width: 20%;
  padding: 8px;

  font-size: 18px;
  background-color: #d6d5d55b;
  border-right: 1px solid #870000;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.input-field input {
  width: 100%;
  padding: 8px;

  font-size: 14px;
  background-color: #d6d5d55b;
  border: none;
  border-radius: 0 5px 5px 0;
  outline: none;
}

.signup_input-field input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #d6d5d55b;
  border: none;
  border-radius: 0 5px 5px 0;
  outline: none;
}
.signup_input-field .num-drpdwn {
  width: 20%;
  padding: 12px;
  margin-bottom: 10px;
  font-size: 18px;
  background-color: #d6d5d55b;
  border-right: 1px solid #870000;
  border-radius: 5px 0 0 5px;
  outline: none;
}
.num {
  display: flex;
  flex-direction: row;
}

.otpinput-field {
  display: flex;
  flex-direction: column;
}
.otpinput-field label {
  font-size: 16px;
  padding: 0.5rem 0;
}

.otpinput-field input {
  height: 42px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.13rem;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #d6d5d55b;
}
.otp-label {
  margin-bottom: 2rem;
}
.otp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

/*=====login &sign up end=====*/

/*=====TAbbar=====*/
.tabbar {
  max-width: 30rem;
  position: fixed;
  width: 100%;
  z-index: 100;
  bottom: 0;
  background: #fff;
  padding: 0 4px;
  box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 0.08);
}
.tabbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  z-index: 1;
}
.tabbar ul li {
  position: relative;
  flex-grow: 1;
}
.tabbar ul li a {
  cursor: pointer;
  display: table;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 60px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.tabbar ul li a div,
.tabbar ul li a span,
.tabbar ul li a svg {
  width: 20px;
  height: 20px;
  display: block;
  -webkit-backface-visibility: hidden;
}
.tabbar ul li a div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -84%);
}
.tabbar ul li a div span {
  width: 20px;
  bottom: 0;
  left: 0;
  transform-origin: 50% 50%;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background: #fff;
  transform: scale(0.94);
  transform-origin: 0 100%;
  -webkit-animation: down 0.3s linear forwards;
  animation: down 0.3s linear forwards;
}
.tabbar ul li a div span svg {
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabbar ul li a div span:first-child {
  height: 20px;
}
.tabbar ul li a div span:first-child svg {
  transition: fill 0.3s ease, stroke 0.3s ease;
  fill: #99a3ba;
  stroke: #99a3ba;
}
.tabbar ul li a div span:last-child {
  height: 0;
  z-index: 5;
  transition: height 0.25s ease;
}
.tabbar ul li a div span:last-child svg {
  fill: #870000;
  stroke: #870000;
}
.tabbar ul li a strong {
  font-size: 10px;
  font-weight: 600;
  margin-top: 28px;
  color: #99a3ba;
  transition: all 0.3s ease;
}
.tabbar ul li a:hover div span:first-child svg {
  fill: #6c7486;
  stroke: #6c7486;
}
.tabbar ul li.active a {
  z-index: 5;
}
.tabbar ul li.active a div span {
  -webkit-animation: high 0.35s linear forwards 0.05s;
  animation: high 0.35s linear forwards 0.05s;
}
.tabbar ul li.active a div span:last-child {
  height: 20px;
  transition: height 0.3s ease 0.25s;
}
.tabbar ul li.active a strong {
  color: #870000;
}
.tabbar em {
  --offset: 0;
  border-radius: 50%;
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: 13px;
  left: 4px;
  margin: 0 0 0 -3px;
  z-index: 4;
  transition: transform 0.4s ease;
  background: #870000;
  transform: translateX(var(--offset));
}

TabPanel {
  padding: 1rem 0.5rem;
}

@-webkit-keyframes high {
  0% {
    transform: rotate(0deg) scale(0.94);
  }
  33% {
    transform: rotate(8deg);
  }
  66% {
    transform: rotate(8deg) translateY(-1px);
  }
  100% {
    transform: rotate(0deg) scale(1) translateY(-1px);
  }
}

@keyframes high {
  0% {
    transform: rotate(0deg) scale(0.94);
  }
  33% {
    transform: rotate(8deg);
  }
  66% {
    transform: rotate(8deg) translateY(-1px);
  }
  100% {
    transform: rotate(0deg) scale(1) translateY(-1px);
  }
}
@-webkit-keyframes down {
  0% {
    transform: rotate(0deg) scale(1) translateY(-1px);
  }
  33% {
    transform: rotate(8deg);
  }
  66% {
    transform: rotate(8deg) translateY(0);
  }
  100% {
    transform: rotate(0deg) scale(0.94) translateY(0);
  }
}
@keyframes down {
  0% {
    transform: rotate(0deg) scale(1) translateY(-1px);
  }
  33% {
    transform: rotate(8deg);
  }
  66% {
    transform: rotate(8deg) translateY(0);
  }
  100% {
    transform: rotate(0deg) scale(0.94) translateY(0);
  }
}

/*====wallet====*/

.wallet {
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 1rem 0;
  color: #ffffff;
  border-radius: 10px;
  background: linear-gradient(55deg, #9c0000, #38150a 110%);
}
.wallet-wrapper {
  padding: 0.5rem 0;
  width: 100%;
}
.wallet-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.wallet-btn-wrapper {
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.wallet-btn-wrapper .btn {
  background: #ffffff;
  color: #000000;
  font-size: 12px !important;
  font-weight: 400;
  width: 6rem;
  border-radius: 5px;
  height: 3.5rem;
}

.history .users-history {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem 3rem;
  row-gap: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.support_container {
  margin: 3rem 0;
}

.support-img {
  max-width: 40px !important;
  width: 100%;
}

.customer-support {
  display: flex;
  flex-direction: column;
}
.customer-support .head-img {
  background: #870000;
  margin-bottom: 15px;
}
.head-img img {
  width: 65%;
  margin-left: 35%;
}
.list {
  background: #fff2a2;
  margin: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.support-img {
  border-radius: 25px !important;
  height: auto;
  border: solid #6c7486;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  max-width: 30rem;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.swiper-slide img {
  max-width: 30rem;
  width: 100%;
  object-fit: cover;
}
.checkbox {
  background-color: #870000;
  color: #ffffff;
}

.profile {
  max-width: 30rem;
  width: 100%;
  background: #870000;
  color: white;
  height: 15rem;
  box-shadow: 0 4px 12px -1px rgba(18, 22, 33, 10%);
  border-radius: 0 0 100% 100%;
  padding: 1.5rem;
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;
}
.profileBalance {
  top: 120px;
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 1rem 0.5rem;
  color: #ffffff;
  row-gap: 15px;
  border-radius: 10px;
  background: linear-gradient(45deg, #870000, #b00000);
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5);
  align-items: flex-start;
  width: 95%;
}
.profile-info ul {
  margin: 0 1rem;
  padding: 0;
  font-size: larger;
}
.profile-List {
  background: #fff;
  margin: 4px 0;
  padding: 0.5rem 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
/* .profile-drawer {
  background: #f4f4f4;
  height: 100vh;
} */
.UID {
  font-size: 14px !important;
  border: 1px solid;
  background: #ffffff50;
  border-radius: 12px;
  padding: 0px 5px;
}
@media only screen and (max-width: 790px) {
  #root {
    width: 100%;
  }

  #app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .header {
    max-width: 30rem;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .tabbar {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .winners {
    background: #ffffff url(../img/winner-bg.svg) no-repeat;
    background-position-x: center;
    background-position-y: -1rem;
    background-size: 24rem;
  }
  .slider {
    max-width: 30rem;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  .slide {
    flex: 0 0 100%;
    transition: transform 1s ease;
  }
  .slider img {
    max-width: 30rem;
    width: 100%;
    object-fit: cover;
  }
  #support {
    position: fixed;
    bottom: 3.4rem;
    left: 22rem;
    width: 50%;
    border-radius: 50%;
    z-index: 100;
  }

  .wallet-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .wallet-btn-wrapper .btn {
    background: #ffffff;
    color: #000000;
    font-size: 12px !important;
    font-weight: 400;
    width: 6rem;
    border-radius: 5px;
    height: 3.5rem;
  }
  .otpinput-field input {
    height: 37px;
    width: 35px;
    border-radius: 6px;
    outline: none;
    font-size: 1.13rem;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #d6d5d55b;
  }
  .join-btn {
    padding: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  #root {
    width: 100%;
  }

  #app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .tabbar {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .winners {
    background: #ffffff url(../img/winner-bg.svg) no-repeat;
    background-position-x: center;
    background-position-y: -1rem;
    background-size: 24rem;
  }
  .slider {
    max-width: 30rem;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  .slide {
    flex: 0 0 100%;
    transition: transform 1s ease;
  }
  .slider img {
    max-width: 30rem;
    width: 100%;
    object-fit: cover;
  }
  #support {
    position: fixed;
    bottom: 3.4rem;
    left: 22rem;
    width: 50%;
    border-radius: 50%;
    z-index: 100;
  }

  .wallet-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .wallet-btn-wrapper .btn {
    background: #ffffff;
    color: #000000;
    font-size: 12px !important;
    font-weight: 400;
    width: 6rem;
    border-radius: 5px;
    height: 3.5rem;
  }
  .otpinput-field input {
    height: 37px;
    width: 35px;
    border-radius: 6px;
    outline: none;
    font-size: 1.13rem;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #d6d5d55b;
  }
  .join-btn {
    padding: 1rem;
  }
}
@media only screen and (max-width: 360px) {
  #root {
    width: 100%;
  }

  #app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  body {
    font-size: 14px;
  }
  .otpinput-field input {
    height: 35px;
    width: 32px;
    border-radius: 5px;
    outline: none;
    font-size: 1.13rem;
    text-align: center;
    border: 1px solid #ddd;
    background-color: #d6d5d55b;
  }

  .wallet {
    padding: 1rem 1rem;
    margin: 1rem 0;
  }
  #support {
    position: fixed;
    bottom: 3.4rem;
    left: 16.5rem;
    width: 100%;
    border-radius: 50%;
    z-index: 100;
  }
  .wallet-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .wallet-btn-wrapper .btn {
    background: #ffffff;
    color: #000000;
    font-size: 12px !important;
    font-weight: 500;
    width: 6rem;
    border-radius: 5px;
    height: 3rem;
  }

  .cntr1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: rgb(255, 144, 144) !important;
    color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  .num-screen {
    padding: 1rem 1rem;
    background-color: #ffffff;
    border-radius: 15px;
    font-size: 30px;
    display: grid;
    justify-content: space-between;
    overflow: hidden;
    grid-gap: 1rem;
    grid-template-rows: repeat(1, 2fr);
    grid-template-columns: repeat(3, 0fr);
  }
}
@media only screen and (max-width: 300px) {
  #root {
    display: none !important;
    overflow: hidden;
  }
}

@keyframes blink {
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: rgb(123, 123, 123);
  }
}

.blinking {
  animation: blink 1s infinite;
}
.redgreen {
  background: #ececec;
  margin: 1rem 0;
  padding: 2rem 1rem;
  row-gap: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100vh;
}
.splash-title img {
  max-width: 10rem;
}

/*===================extra====================*/

.swiper-slide {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

.refferal-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  background: #ffffff;
  margin: 1rem 0;
  padding: 0 1rem;
  row-gap: 5px;
}
.referral-code {
  text-align: center;
  background-color: #870000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}

.invite-friends {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid #d4d4d4;
  padding-bottom: 1rem;
}

.invite {
  font-size: large;
  text-align: center;
  border: 3px dotted #000000;
  color: #870000;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
  padding: 0.5rem 0;
}
.invite button {
  background: none;
  border: none;
  font-size: 16px;
}

.share-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.share-icon a {
  font-size: 24px;
  color: #870000 !important ;
  cursor: pointer;
}
