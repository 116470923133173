.cntr1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #00810e !important;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.css-1chdh8w {
  margin-top: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
}

.rule h5 {
  border: 1px solid #ffffff;
  border-radius: 25px;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
}
.rule p {
  margin-left: 1rem !important;
}

.timer p {
  font-size: 24px;
  text-align: end;
}

.custom-modal .modal-btn {
    background: #870000;
  }
  .custom-modal .modal-btn:hover {
    background: #b00000;
  }



  /*=========Game Bets==========*/
#nested-modal-title{
  text-align: center;
  margin: 1rem 0 ;
  padding: 1rem;
  background-color: #870000;
  border-radius: 5px;
  color: #ffffff;
}
.proceed{
  width:65%;
}
.cancle{
  background: #3d3d3d !important; 
  width:30%;
}
@media (max-width: 768px) {
  .custom-modal {
    transition: transform 0.3s ease-in-out;
    animation: modal-slide-up 0.1s ease-in-out forwards; /* Slide up animation */
  }

  /* Keyframes for the modal slide-up animation */
  @keyframes modal-slide-up {
    0% {
      transform: translateX(0) translateY(100%); /* Start off-screen below */
    }
    100% {
      transform: translateX(0) translateY(0); /* Slide up to its final position */
    }
  }
  .custom-modal-dialog.css-m2e0od-JoyModalDialog-root {
    padding: 3rem 1rem;
    border-radius: 25px 25px 0 0;
  }
  
}

.add-money {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.add-money Button {
  background: #870000;
}
.add-money Button:hover {
  background: #b00000;
}
.add-money input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 0.5px solid;
  font-size: 18px;
  border-radius: 5px;
  outline: none;
}

.add-instant{
  display: grid;
  justify-content: space-evenly;
  grid-gap: 1rem;
  grid-template-rows: repeat(1, 2fr);
  grid-template-columns: repeat(2, 2fr);
}
.add-instant Button {
  max-width: 30rem;
  background: #c64e4e;
}
.add-instant Button:hover {
  background: #870000;
}
